require('./bootstrap');

require('../../node_modules/flickity/dist/flickity.pkgd.min.js');

$(document).ready(function(){
    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('#nav-links').toggleClass("show");
        $('#mainnav').toggleClass("menu-open");
        $('#bg-overlay').toggleClass("show");
    });
    $("#bg-overlay").click(function(){
        $(this).removeClass("show");
        $('#nav-links').removeClass("show");
        $('.hamburger').removeClass("is-active");
        $('#mainnav').removeClass("menu-open");
    });


    var headerHeight = $('.mainnav').outerHeight();
    $('.scrollto').click(function(e) {

        //Toggle Menu if open
        $('#nav-links').removeClass("show");
        $('#mainnav').removeClass("menu-open");
        $('#bg-overlay').removeClass("show");

        var targetHref = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(targetHref).offset().top - (headerHeight+24)
        }, 1000);
        e.preventDefault();
    });
});
